import Vue from 'vue'
import { upperFirst, camelCase } from 'lodash'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import App from './App.vue'
import router from './router'
import store from './store'
// 引入icon
import './assets/icon/iconfont.css'
import VDistpicker from 'v-distpicker'

import VueAreaLinkage from 'vue-area-linkage'

// 过滤器
import commonUtils from '@/utils/common-utils'
import config from '@/config'
// App 内的样式
// 只能由这一个入口, 不得引入其他
import '@/assets/scss/index.scss'
import '@/assets/scss/element-variables.scss'
import 'vue-area-linkage/dist/index.css' // v2 or higher

// import '@/assets/style/css/swiper.min.css'
import '@/assets/style/css/bootstrap.min.css'
import '@/assets/style/css/app.css'
import '@/assets/style/css/app_web.css'
import * as custom from './utils/convert-utils'
import moment from 'moment'
import 'swiper/css/swiper.css'

// import login from './views/login-pop'
// import vip from './views/vip-pop'
// import resourcePay from './views/resource-pop'
// 全局登录窗口
// Vue.prototype.$login = login.install
// // 全局会员购买窗口
// Vue.prototype.vip = vip.install
// // 资源购买
// Vue.prototype.$resourcePay = resourcePay.install

Vue.component('v-distpicker', VDistpicker)
Vue.prototype.$config = config
Vue.prototype.commonUtils = commonUtils

Vue.prototype.$moment = moment// 赋值使用

Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key])
})

Vue.config.productionTip = false

Vue.use(VueAreaLinkage)
Vue.use(ElementUI)

// ================ 基础组件的自动化全局注册 begin ================
const requireComponent = require.context(
  // 其组件目录的相对路径
  './views/components',
  // 是否查询其子目录
  true,
  // 匹配基础组件文件名的正则表达式
  /index.vue$/
)
requireComponent.keys().forEach(fileName => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName)

  // 获取组件的 PascalCase 命名
  const componentName = upperFirst(
    camelCase(
      // 获取和目录深度无关的文件名
      fileName.split('/')[1].replace(/\.\w+$/, '')
    )
  )
  // 全局注册组件
  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})

new Vue({
  router,
  store,
  data: {
    // 空的实例放到根组件下，所有的子组件都能调用
    Bus: new Vue()
  },
  render: h => h(App)
}).$mount('#app')
