import axios from '@/api/api-request'

const projectUrl = '/baseCollectionAccount'

export const getInfos = (data) => {
  return axios.request({
    url: projectUrl + '/getInfos.do',
    method: 'post',
    data: data
  })
}
