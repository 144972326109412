<template>
  <div>
    <div id="qrcode"></div> <!-- 创建一个div，并设置id为qrcode -->
  </div>
</template>

<script>
import QRCode from 'qrcodejs2' // 引入qrcode
export default {
  name: 'test',
  data () {
    return {
      qrcode: null
    }
  },
  mounted () {
    this.qrcodeg()
  },
  props: {
    url: {
      type: String,
      required: true,
      default: ''
    }
  },
  methods: {
    qrcodeg () {
      this.qrcode = new QRCode('qrcode', {
        width: 162,
        height: 162,
        text: this.url, // 二维码地址
        colorDark: '#000',
        colorLight: '#fff'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  #qrcode {
    display: inline-block;
    img {
      width: 162px;
      height: 162px;
      background-color: #fff; //设置白色背景色
      padding: 6px; // 利用padding的特性，挤出白边
    }
  }
</style>
