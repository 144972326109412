<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  created () {
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style>
  html,
  body {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    background: rgb(249, 249, 249);
  }
  #app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #4f080e;
    width : 100%;
    height: 100%;
  }
  .custom-tree-node p{
    display: inline-block;
  }
</style>
