import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import constant from './../config/index'
const addErrorLog = errorInfo => {
  const { statusText, status, request: { responseURL } } = errorInfo
  let info = {
    type: 'ajax',
    code: status,
    mes: statusText,
    url: responseURL
  }
  if (!responseURL.includes('save_error_logger')) store.dispatch('addErrorLog', info)
}

const clearCachedUserInfo = () => {
  try {
    Vue.prototype.commonUtils.localStorageRemove('token')
  } catch (e) {}
}

class HttpRequest {
  constructor (baseUrl) {
    this.baseUrl = baseUrl
    this.queue = {}
  }
  getInsideConfig () {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        //
      }
    }
    return config
  }
  destroy (url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }
  interceptors (instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      config => {
        if (constant.noAuthApi.indexOf(url) === -1) {
          let token = Vue.prototype.commonUtils.localStorageGet('token')
          if (token) {
            config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带自定义token 请根据实际情况自行修改
          }
        }
        if (Vue.prototype.commonUtils.localStorageGet('token') && url === '/login/getSysInfo.do') {
          config.headers['Authorization'] = 'Bearer ' + Vue.prototype.commonUtils.localStorageGet('token')
        }
        return config
      },
      config => {
        // 添加全局的loading...
        if (!Object.keys(this.queue).length) {
          // Spin.show() // 不建议开启，因为界面不友好
        }
        this.queue[url] = true
        return config
      }, error => {
        return Promise.reject(error)
      })
    // 响应拦截
    instance.interceptors.response.use(res => {
      this.destroy(url)
      const { data, status } = res
      return { data, status }
    }, error => {
      const ret = error.response
      if (!ret || [401, 403].includes(ret.status)) {
        /* let m = 1
        if (m === 1) {
          return
        } */
        clearCachedUserInfo()
        if (url === '/login/getSysInfo.do') {
          window.location.reload()
        } else {
          // window.location.href = process.env.BASE_URL // '/login'
        }
      }
      this.destroy(url)
      let errorInfo = error.response
      if (!errorInfo) {
        const { request: { statusText, status }, config } = JSON.parse(JSON.stringify(error))
        errorInfo = {
          statusText,
          status,
          request: { responseURL: config.url }
        }
      }
      addErrorLog(errorInfo)
      return Promise.reject(error)
    })
  }
  request (options) {
    const instance = axios.create()
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance, options.url)
    return instance(options)
  }
}
export default HttpRequest
