<template>
  <el-pagination class="page-box" @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                 :current-page="childMsg.currentPage" :page-sizes="childMsg.pageSizes"
                 :page-size="childMsg.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="childMsg.total">
  </el-pagination>
</template>
<script>
export default {
  name: 'pagination',
  props: ['childMsg'],
  data () {
    return {
      pageParam: {
        currentPage: this.childMsg.currentPage,
        pageSize: this.childMsg.pageSize
      }
    }
  },
  created () {
    if (!this.childMsg.pageSizes) {
      this.childMsg.pageSizes = [9, 18, 27, 36, 81]
    }
  },
  methods: {
    handleSizeChange (val) {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      this.pageParam.pageSize = val
      this.$emit('callFather', this.pageParam)
    },
    handleCurrentChange (val) {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      this.pageParam.currentPage = val
      this.$emit('callFather', this.pageParam)
    }
  }
}
</script>

<style>
.page-box {
  margin: 10px auto;
  text-align: center;
}
.page-box.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e60202 !important;
}
.page-box .el-select-dropdown__item.selected {
  color: #e60202;
}
.page-box .el-select .el-input.is-focus .el-input__inner {
  border-color: #e60202;
}
.page-box .el-pagination__sizes .el-input .el-input__inner:hover {
  border-color: #e60202;
}
.page-box .el-input__inner:focus {
  outline: none;
  border-color: #e60202;
}
.el-select-dropdown .el-select-dropdown__item.selected {
  color: #e60202;
}
</style>
