<template>
  <el-upload
    class="upload-demo"
    :action="baseUrl + uploadUrl"
    :headers="headers"
    :on-success="uploadSuccessFun"
    :before-upload="beforeUploadFun"
    :show-file-list="false"
    :format="['jpg','jpeg','png']"
    list-type="picture-card">
    <img v-if="imgUrl" :src="imgUrl" class="avatar">
    <i v-if="!imgUrl" class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
</template>

<script>
import config from '@/config'

let conBaseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro
export default {
  name: 'index',
  data () {
    return {
      headers: {
        Authorization: 'Bearer ' + this.commonUtils.localStorageGet('token')
      },
      baseUrl: conBaseUrl,
      imgUrl: ''
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    uploadUrl: {
      type: String,
      default: '/file/uploadImgWeb.do'
    }
  },
  mounted () {
  },
  methods: {
    beforeUploadFun (file) {
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 5MB!')
        return false
      }
    },
    uploadSuccessFun (response, file, fileList) {
      if (response.rtnCode === '0') {
        this.imgUrl = response.bizData.filePath
      } else {
        this.$message.error('上传图片失败，请稍后再试！')
      }
    }
  },
  watch: {
    value (newValue) {
      this.imgUrl = newValue
    },
    imgUrl (newValue) {
      this.$emit('input', newValue)
      this.imgUrl = newValue
    }
  }
}
</script>

<style scoped>
  .avatar {
    width: 100%;
    height: 100%;
  }
</style>
