<template>
  <div>
    <div v-if="payType === '2'">
      <el-row>
        <el-col :span="24">
          开户银行：{{paymentInfo.collectionBank}}
        </el-col>
        <el-col :span="24">
          账号名称：{{paymentInfo.collectionName}}
        </el-col>
        <el-col :span="24">
          银行账号：{{paymentInfo.collectionBankNo}}
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div>
        <el-row style="width: 80%">
          <el-col :span="24">
            <div style="margin-bottom: 20px">
              <span>转账成功后请上传转账凭证，审核通过后受理</span>
            </div>
            <div style="width:80%;display: inline-block; margin-right: 10px">
              <el-upload
                class="upload-demo"
                :headers="{'Authorization': 'Bearer ' + this.commonUtils.localStorageGet('token')}"
                :action="imageUrl"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeUpload"
                :on-error="onError"
                v-loading="loading"
                :show-file-list="false">
                <el-button size="small" type="primary">上传支付凭证</el-button>
              </el-upload>
              <div style="line-height: 24px;margin-top: 10px;margin-left: 5px;">{{fileName}}</div>
              <!--<el-input style="width:400px;margin-top: 15px;" v-model="fileName" placeholder="文件名称" readonly></el-input>-->
            </div>
          </el-col>
        </el-row>
        <!--<span>转账成功后请上传转账凭证，审核通过后受理</span>
        <single-pic-upload style="margin-top: 20px" v-model="filePath"
                           uploadUrl="/VipOrder/uploadTransferVoucherFile.do"></single-pic-upload>-->
      </div>
    </div>
    <el-row v-if="payType === '0'">
      <el-col :span="24" style="text-align: center">
        <h1>请使用微信扫描支付</h1>
        <div>
          <img :src="qrCode.qrcode">
        </div>
        <el-button size="medium" type="text" @click="getQrcode">刷新二维码</el-button>
      </el-col>
    </el-row>
    <el-row v-if="payType === '1'">
      <el-col :span="24" style="text-align: center">
        <h1>请使用支付宝扫描支付</h1>
        <div>
          <img :src="qrCode.qrcode">
        </div>
        <el-button size="medium" type="text" @click="getQrcode">刷新二维码</el-button>
      </el-col>
    </el-row>
    <div style="text-align: center;
    font-size: 16px;
    color: red;">{{sendMsg}}</div>
    <el-dialog title="支付成功" :visible="paySuccessVisible" v-if="paySuccessVisible" :destroy-on-close="true"
               width="40%" @click="closeDialog" center
               :close-on-click-modal="false"
               :close-on-press-escape="false" append-to-body>
      <el-alert
        title="支付成功"
        type="success" :closable="false"
        description="订单支付成功，恭喜您成为会员！"
        show-icon>
      </el-alert>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSuccess">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import config from '@/config'
import { getWxQrcode, getZfbQrcode, getInfo } from '@/api/user/userVip'
import { getInfos } from '@/api/platform/collectionAccount'

export default {
  name: 'index',
  data: function () {
    return {
      loading: false,
      qrCode: {},
      paymentInfo: {},
      prevTid: '',
      filePath: '',
      fileName: '',
      paySuccessVisible: false,
      testInspectionOrgEdit: {},
      imageUrl: (process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro) + '/VipOrder/uploadTransferVoucherFile.do'
    }
  },
  props: {
    orderId: {
      type: String,
      required: true,
      default: ''
    },
    payType: {
      type: String,
      required: true,
      default: ''
    },
    sendMsg: {
      type: String,
      required: false,
      default: ''
    }
  },
  watch: {
  },
  beforeDestroy () {
    clearInterval(this.prevTid)
  },
  mounted () {
    if (this.payType === '2') {
      this.getAcountInfos()
    } else {
      this.getQrcode()
      this.prevTid = setInterval(this.getOrderInfo, 3000)
    }
  },
  methods: {
    beforeUpload () {
      this.loading = true
    },
    onError () {
      this.loading = false
    },
    handleAvatarSuccess: function (res, file, fileList) {
      if (res.rtnCode !== '0') {
        this.loading = false
        this.$message.error(res.msg)
        return false
      }
      this.filePath = res.bizData.filePath
      this.fileName = res.bizData.fileTitle
      this.loading = false
    },
    getQrcode () {
      if (this.payType === '0') {
        this.getWxQrcodeFun()
      } else if (this.payType === '1') {
        this.getZfbQrcodeFun()
      }
    },
    getWxQrcodeFun () {
      let param = {}
      param.orderId = this.orderId
      getWxQrcode(param).then(res => {
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          if (result.bizData) {
            this.qrCode = result.bizData
          }
        }
      }).catch(ex => {
        this.$message.error('获取微信验证码失败，请稍后再试！')
      })
    },
    getAcountInfos () {
      let param = {}
      getInfos(param).then(res => {
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          if (result.bizData) {
            this.paymentInfo = result.bizData
          }
        }
      }).catch(ex => {
        this.$message.error('获取微信验证码失败，请稍后再试！')
      })
    },
    getZfbQrcodeFun () {
      let param = {}
      param.orderId = this.orderId
      getZfbQrcode(param).then(res => {
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          if (result.bizData) {
            this.qrCode = result.bizData
          }
        }
      }).catch(ex => {
        this.$message.error('获取支付宝验证码失败，请稍后再试！')
      })
    },
    handleSuccess () {
      this.$emit('submitpay')
    },
    getOrderInfo () {
      let param = {}
      param.orderId = this.orderId
      getInfo(param).then(res => {
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          if (result.bizData.payStatus === '0') {
            clearInterval(this.prevTid)
            this.paySuccessVisible = true
          }
        }
      }).catch(ex => {
        this.$message.error('获取支付宝验证码失败，请稍后再试！')
      })
    }
  }
}
</script>

<style scoped>
  img {
    width: 260px;
    height: 260px;
  }
</style>
