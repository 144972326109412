export default [
  {
    path: '/',
    name: 'home',
    redirect: '/home-layout',
    meta: {
      title: '官网'
    },
    component: () => import('@/views/home/index'),
    children: [
      {
        path: '/home-layout',
        name: 'home-layout',
        meta: {
          title: '官网'
        },
        component: () => import('@/views/home/home-layout')
      },
      {
        path: '/mall',
        name: 'mall',
        meta: {
          title: '产品中心'
        },
        component: () => import('@/views/mall/index')
      },
      {
        path: '/solution',
        name: 'solution',
        meta: {
          title: '培训资料'
        },
        component: () => import('@/views/solution/index')
      },
      {
        path: '/news',
        name: 'news',
        meta: {
          title: '新闻资讯'
        },
        component: () => import('@/views/news/index')
      },
      {
        path: '/reorgs',
        name: 'reorgs',
        meta: {
          title: '茶园基地'
        },
        component: () => import('@/views/reorgs/index')
      },
      {
        path: '/reservice',
        name: 'reservice',
        meta: {
          title: '关于我们'
        },
        component: () => import('@/views/reservice/index')
      },
      {
        path: '/topic',
        name: 'topic',
        meta: {
          title: '品牌荣誉'
        },
        component: () => import('@/views/topic/index')
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        meta: {
          title: '联系我们'
        },
        component: () => import('@/views/about-us/contact-us/contactus')
      },
      {
        path: '/serviceProject',
        name: 'serviceProject',
        meta: {
          title: '招商加盟'
        },
        component: () => import('@/views/about-us/service-project/index')
      },
      {
        path: '/goods/detail',
        name: 'goods',
        meta: {
          title: '商品介绍'
        },
        component: () => import('@/views/mall/detail')
      },
      {
        path: '/topic/detail',
        name: 'topic',
        meta: {
          title: '专题推荐'
        },
        component: () => import('@/views/topic/detail')
      },
      {
        path: '/news/detail',
        name: 'news',
        meta: {
          title: '新闻资讯'
        },
        component: () => import('@/views/news/detail')
      }
    ]
  },
  {
    path: '/401',
    name: 'error_401',
    meta: {
      hideInMenu: true
    },
    component: () => import('@/views/error-page/401.vue')
  },
  {
    path: '/500',
    name: 'error_500',
    meta: {
      hideInMenu: true
    },
    component: () => import('@/views/error-page/500.vue')
  },
  {
    path: '*',
    name: 'error_404',
    meta: {
      hideInMenu: true
    },
    component: () => import('@/views/error-page/404.vue')
  }
]
