export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: '敏维生物',
  /**
   * @description api请求基础路径
   */
  baseUrl: {
    dev: 'https://www.minweisw.com/webapi',
    // dev: 'https://www.origtek.cn/webapi',
    // pro: 'http://localhost:8109/webapi'
    pro: 'https://www.minweisw.com/webapi'
  },
  /**
   * @description 默认打开的首页的路由name值，默认为homeH
   */
  homeName: 'home',
  loginName: 'login',
  /**
   * @description 需要加载的插件
   */
  plugin: {
    'error-store': {
      showInHeader: true, // 设为false后不会在顶部显示错误日志徽标
      developmentOff: true // 设为true后在开发环境不会收集错误信息，方便开发中排查错误
    }
  },
  noAuthApi: [
    '/login/login.do',
    '/login/findPassword.do',
    '/login/sendVerifiCode.do',
    '/login/sendFindPasswordVerifiCode.do',
    '/login/sendLoginVerifiCode.do',
    '/login/isMobileRegistered.do',
    '/login/isAccountRegistered.do',
    '/login/register.do',
    '/login/getSysInfo.do',
    '/webLaws/getFirst.do',
    '/webMemberPrice/getLists.do',
    '/webLaws/list.do',
    //  '/webLaws/getInfo.do',
    '/webFormulat/getFirst.do',
    '/webFormulat/list.do',
    // '/webFormulat/getInfo.do',
    '/webTestStandard/getFirst.do',
    '/webTestStandard/list.do',
    //  '/webTestStandard/getInfo.do',
    '/webNoticeIndustry/getFirst.do',
    '/webNoticeIndustry/list.do',
    '/webNoticeIndustry/getInfo.do',
    '/webNotice/getFirst.do',
    '/webNotice/list.do',
    '/webNotice/getInfo.do',
    '/webPolicy/getFirst.do',
    '/webPolicy/list.do',
    '/webPolicy/getInfo.do',
    '/webTestContent/getInfo.do',
    '/webTestContent/getLists.do',
    '/webAdsPicture/getLists.do',
    '/webAdsText/getLists.do',
    '/webAdsText/getInfo.do',
    '/webSolution/getFirst.do',
    '/webSolution/getLists.do',
    '/webSolution/getInfo.do',
    '/webSolution/list.do',
    '/webTopic/getFirst.do',
    '/webTopic/getLists.do',
    '/webTopic/getInfo.do',
    '/webRecommendTesting/getFirst.do',
    '/webRecommendTesting/getLists.do',
    '/webRecommendTesting/getInfo.do',
    '/webCoorperationCompany/getLists.do',
    '/webCoorperationCompany/getInfo.do',
    '/webOnlineConsultation/add.do',
    '/webOnlineConsultation/getInfo.do',
    '/resourceManage/getResourceLists.do',
    '/mallGoods/list.do',
    '/mallGoods/getInfo.do'
  ]
}
