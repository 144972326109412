<template>
    <span @click="linkTo">
      <slot></slot>
    </span>
</template>

<script>
export default {
  name: 'index',
  props: {
    path: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    linkTo () {
      this.$router.push({
        path: this.path
      })
    }
  }
}
</script>

<style scoped>

</style>
