<template>
  <el-card style="margin-top: 10px">
    <div style="height: 100px;width: 100%;text-align: center;vertical-align: center;line-height: 100px;">暂无数据</div>
  </el-card>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style scoped>

</style>
