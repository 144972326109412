import store from '@/store'

export default class commonUtils {
  static btnAuth = (str) => {
    if (store.state.user.access[str]) {
      return true
    } else {
      return false
    }
  }
  /**
   * localStorage封装
   * @param key
   */
  static localStorageSet = (key, value) => {
    if (typeof (localStorage.getItem(key)) !== 'undefined' && localStorage.getItem(key) !== 'undefined' && localStorage.getItem(key) !== '') {
      localStorage.setItem(key, JSON.stringify(value))
    }
  }

  static localStorageGet = (key) => {
    if (typeof (localStorage.getItem(key)) !== 'undefined' && localStorage.getItem(key) !== 'undefined' && localStorage.getItem(key) !== '') {
      return JSON.parse(localStorage.getItem(key))
    } else {
      return ''
    }
  }

  static localStorageRemove = (key) => {
    if (typeof (localStorage.getItem(key)) !== 'undefined' && localStorage.getItem(key) !== 'undefined' && localStorage.getItem(key) !== '') {
      localStorage.removeItem(key)
    }
  }

  static localStorageClear = () => {
    localStorage.clear()
  }

  /**
   * sessionStorage 封装
   * @param key
   * @param value
   */
  static sessionStorageSet = (key, value) => {
    if (typeof (localStorage.getItem(key)) !== 'undefined' && localStorage.getItem(key) !== 'undefined' && localStorage.getItem(key) !== '') {
      sessionStorage.setItem(key, JSON.stringify(value))
    }
  }

  static sessionStorageGet = (key) => {
    if (typeof (localStorage.getItem(key)) !== 'undefined' && localStorage.getItem(key) !== 'undefined' && localStorage.getItem(key) !== '') {
      return JSON.parse(sessionStorage.getItem(key))
    } else {
      return ''
    }
  }

  static sessionStorageRemove = (key) => {
    if (typeof (localStorage.getItem(key)) !== 'undefined' && localStorage.getItem(key) !== '') {
      sessionStorage.removeItem(key)
    }
  }

  static sessionStorageClear = () => {
    sessionStorage.clear()
  }
  // 获取参数
  static getPara = (key) => {
    let lsDic = JSON.parse(sessionStorage.getItem('params'))
    return lsDic[key]
  }
  static getDictPara = (key) => {
    let lsDic = JSON.parse(sessionStorage.getItem('ALL_DIC_PARA')).filter((dict) => {
      return dict.code === key
    })
    if (lsDic.length === 1) {
      return lsDic[0].params
    }
    return []
  }

  static getDict = (key, value) => {
    let lsDic = JSON.parse(sessionStorage.getItem('ALL_DIC_PARA'))
    let lsSel = lsDic[key].filter((dict) => {
      return dict.value === value
    })
    if (lsSel.length === 1) {
      return lsSel[0]
    }
    return ''
  }
  static getDictName = (key, value) => {
    let lsDic = JSON.parse(sessionStorage.getItem('ALL_DIC_PARA'))
    let lssSel = lsDic.filter((dict) => {
      return dict.code === key
    })
    if (lssSel.length === 1) {
      let lsSel = lssSel[0].params.filter((dict) => {
        return dict.code === value
      })
      if (lsSel.length === 1) {
        return lsSel[0].name
      }
    }
    return ''
  }
  static getLastYears = (count) => {
    let time = new Date()
    let curyear = time.getFullYear()
    let arrYears = []
    for (var i = 0; i < count; i++) {
      arrYears.push({ id: curyear, name: curyear })
      curyear--
    }
    return arrYears
  }
  static numToPercent = (str) => {
    let realVal = ''
    if (!isNaN(str) && str !== '') {
      // 截取当前数据到小数点后三位
      let tempVal = (parseFloat(str) * 100).toFixed(1)
      if (tempVal.toString() === 'Infinity ' || !isFinite(tempVal)) {
        realVal = '--'
      } else {
        realVal = tempVal + '%'
      }
    } else {
      realVal = '--'
    }
    return realVal
  }

  /**
   * 根据出生日期转年龄
   */
  static getAge (val) {
    if (val !== null) {
      var birthdayTime = val.split('-')
      var birthYear = birthdayTime[0]
      var d = new Date()
      var nowYear = d.getFullYear()
      var age = nowYear - birthYear
      return age
    }
  }

  /**
   * 将日期转为yyyy-MM-dd格式
   */
  static dateToStr (date) {
    if (date !== null) {
      var strData = new Date(date)
      var month = strData.getMonth() + 1
      var day = strData.getDate()

      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      return strData.getFullYear() + '-' + month + '-' + day
    }
  }
}
