import Vue from 'vue'
import Router from 'vue-router'
import routes from './routers'
import { setTitle } from '@/utils/router-utils'
import store from '@/store'
import ElementUI from 'element-ui'
import { getInfo } from '@/api/resource/resourceview'
import { getParamx } from '@/api/user/login'
// import config from '@/config'

// const { homeName } = config

const LOGIN_PAGE_NAME = '/login'
const TOKEN = 'token'
const USERINFO = '/userCenter/userInfo'
const VIPINFO = '/userCenter/vipLevel'

Vue.use(Router)
// 获取原型对象上的push函数
const originalPush = Router.prototype.push
// 修改原型对象中的push方法
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
  base: '/',
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  getParamx().then(res => {
    var result = res.data
    if (result.rtnCode !== '0') {
    } else {
      sessionStorage.setItem('params', res.data.bizData.params)
    }
  }).catch(ex => {
  })
  // 先判断路由是否登录访问
  let token = ''
  try {
    token = JSON.parse(window.localStorage.getItem(TOKEN))
  } catch (e) {
  }
  /* // 注册记录路劲
  if ((to.path === '/register' || to.path === '/forgetPassword' || to.path === '/login') &&
    (from.path !== '/register' && from.path !== '/forgetPassword' && from.path !== '/login')) {
    sessionStorage.setItem('backUrl', from.fullPath)
  } */
  // 注册记录路劲
  if ((to.path !== '/register' && to.path !== '/forgetPassword' && to.path !== '/login')) {
    sessionStorage.setItem('backUrl', to.fullPath)
    if ((to.path.indexOf('/standard/detail') > -1 || to.path.indexOf('/laws/detail') > -1 || to.path.indexOf('/formula/detail') > -1)) {
      sessionStorage.setItem('backUrl', from.fullPath)
    }
  }
  if (LOGIN_PAGE_NAME === to.path) {
    next()
  }
  // 免费观看
  if ((to.path.indexOf('/standard/detail') > -1 || to.path.indexOf('/laws/detail') > -1 || to.path.indexOf('/formula/detail') > -1)) {
    if (to.query.viewType === '1') {
      next()
      return
    }
  }
  if (to.meta.needLogin && (LOGIN_PAGE_NAME === from.path || from.path === '/register' || from.path === '/forgetPassword')) {
    ElementUI.Message.warning('请登录后访问!')
  } else if (to.meta.needLogin && !token) {
    // 判断是都需要登录才能访问
    Vue.prototype.$login({})
    // ElementUI.Message.warning('请登录后访问!')
    // next(LOGIN_PAGE_NAME)
  } else if (store.state.user.hasGetInfo) {
    if (to.meta.needCompany && (!store.state.user.companyInfo || !store.state.user.companyInfo.companyCode)) {
      // 如果没有公司信息，先让填写
      ElementUI.Message.warning('请填写公司信息后访问!')
      next(USERINFO)
    } else if ((to.path.indexOf('/standard/detail') > -1 || to.path.indexOf('/laws/detail') > -1 || to.path.indexOf('/formula/detail') > -1)) {
      // 免费观看
      if (to.query.viewType === '1') {
        next()
      } else if (to.query.viewType === '2') {
        // 会员观看
        // 非会员
        if ((!store.state.user.vipInfo || !store.state.user.vipInfo.vipEdate)) {
          Vue.prototype.vip({})
        } else {
          next()
        }
      } else if (to.query.viewType === '3') {
        // 付费观看
        getInfo({ tableType: to.query.tableType, rowId: to.query.sid })
          .then(res => {
            var result = res.data
            if (result.rtnCode !== '0') {
              this.$message({
                type: 'info',
                message: result.msg
              })
            } else {
              if (!result.bizData.right) {
                // 价格异常 or 0
                if (isNaN(result.bizData.prices.viewPrice) || parseFloat(result.bizData.prices.viewPrice) === 0) {
                  next()
                  return
                }
                // 会员
                if (store.state.user.vipInfo && (isNaN(result.bizData.prices.viewVipPrice) || parseFloat(result.bizData.prices.viewVipPrice) === 0)) {
                  next()
                  return
                }
                // 付费
                // ElementUI.Message.warning('请付费!')
                Vue.prototype.$resourcePay({
                  next: next,
                  to: to,
                  params: { tableType: to.query.tableType, rowId: to.query.sid }
                })
              } else {
                next()
              }
            }
          }).catch(ex => {
            ElementUI.Message.warning('获取类型异常!')
          })
      } else {
        Vue.prototype.vip({
          next: next,
          to: to
        })
      }
    } else if (to.meta.needVip && (!store.state.user.vipInfo || !store.state.user.vipInfo.vipEdate)) {
      // ElementUI.Message.warning('注册会员后访问!')
      // next(VIPINFO)
      Vue.prototype.vip({})
    } else if (to.path === '/userCenter/myOrg' && (store.state.user.companyInfo && store.state.user.companyInfo.parentId)) {
      next('/401')
    } else {
      next()
    }
  } else {
    store.dispatch('getSysInfo').then(user => {
      if (to.meta.needCompany && (!store.state.user.companyInfo || !store.state.user.companyInfo.companyCode)) {
        // 如果没有公司信息，先让填写
        ElementUI.Message.warning('请填写公司信息后访问!')
        next(USERINFO)
      } else if ((to.path.indexOf('/standard/detail') > -1 || to.path.indexOf('/laws/detail') > -1 || to.path.indexOf('/formula/detail') > -1)) {
        // 免费观看
        if (to.query.viewType === '1') {
          next()
        } else if (to.query.viewType === '2') {
          // 会员观看
          // 非会员
          if ((!store.state.user.vipInfo || !store.state.user.vipInfo.vipEdate)) {
            ElementUI.Message.warning('注册会员后访问!')
          } else {
            next()
          }
        } else if (to.query.viewType === '3') {
          // 付费观看
          next()
        } else {
          Vue.prototype.vip({})
        }
      } else if (to.meta.needVip && (!store.state.user.vipInfo || !store.state.user.vipInfo.vipEdate)) {
        ElementUI.Message.warning('注册会员后访问!')
        next(VIPINFO)
      } else if (to.path === '/userCenter/myOrg' && (store.state.user.companyInfo && store.state.user.companyInfo.parentId)) {
        next('/401')
      } else {
        next()
      }
    }).catch(() => {
      // next()
    })
  }
})

router.afterEach(to => {
  setTitle(to, router.app)
  window.scrollTo(0, 0)
})
export default router
