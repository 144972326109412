import axios from '@/api/api-request'

const projectUrl = '/webResourceView'

// 判断是否可以查看
export const getInfo = (data) => {
  return axios.request({
    url: projectUrl + '/getInfo.do',
    method: 'post',
    data: data
  })
}

// 获取价格
export const getResourcePrice = (data) => {
  return axios.request({
    url: projectUrl + '/getResourcePrice.do',
    method: 'post',
    data: data
  })
}

/**
 * 资源查看-获取微信付款二维码
 */
export const getPayQrcode = (params) => {
  return axios.request({
    url: projectUrl + '/getPayQrcode.do',
    method: 'post',
    data: params
  })
}
