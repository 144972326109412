import axios from '@/api/api-request'

const projectUrl = '/VipOrder'

/**
 * 会员充值-获取微信付款二维码
 */
export const getWxQrcode = (params) => {
  return axios.request({
    url: projectUrl + '/getWxQrcode.do',
    method: 'get',
    params: params
  })
}

export const getInfo = (params) => {
  return axios.request({
    url: projectUrl + '/getInfo.do',
    method: 'get',
    params: params
  })
}
/**
 * 会员充值-获取支付宝付款二维码
 */
export const getZfbQrcode = (params) => {
  return axios.request({
    url: projectUrl + '/getZfbQrcode.do',
    method: 'get',
    params: params
  })
}

/**
 * 会员充值-会员开通
 */
export const openVip = (data) => {
  return axios.request({
    url: projectUrl + '/openVip.do',
    method: 'post',
    data: data
  })
}
/**
 * 会员充值-保存转账凭证
 */
export const saveTransfer = (params) => {
  return axios.request({
    url: projectUrl + '/saveTransfer.do',
    method: 'post',
    params: params
  })
}
/**
 * 会员充值-获取有效订单全部列表
 * @returns {*|{responseURL}}
 */
export const getLists = () => {
  return axios.request({
    url: projectUrl + '/getLists.do',
    method: 'post'
  })
}
/**
 * 会员充值-获取有效订单全部列表
 * @returns {*|{responseURL}}
 */
export const setVip = (params) => {
  return axios.request({
    url: projectUrl + '/setVip.do',
    method: 'post',
    params: params
  })
}
