import Vue from 'vue'
import { login, logout, getSysInfo } from '@/api/user/login'

export default {
  state: {
    userInfo: null,
    companyInfo: null,
    vipInfo: null,
    hasGetInfo: false,
    isLogin: false
  },
  mutations: {
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },
    setIsLogin (state, isLogin) {
      state.isLogin = isLogin
    },
    setCompanyInfo (state, companyInfo) {
      state.companyInfo = companyInfo
    },
    setHasGetInfo (state, hasGetInfo) {
      state.hasGetInfo = hasGetInfo
    },
    setVipInfo (state, vipInfo) {
      state.vipInfo = vipInfo
    }
  },
  getters: {
  },
  actions: {
    // 登录
    handleLogin ({ state, commit }, loginForm) {
      return new Promise((resolve, reject) => {
        login(loginForm)
          .then(res => {
            if (res.data.rtnCode === '0') {
              commit('setUserInfo', res.data.bizData.userInfo)
              commit('setCompanyInfo', res.data.bizData.companyInfo)
              commit('setIsLogin', true)
              if (res.data.bizData.vipInfo) {
                commit('setVipInfo', res.data.bizData.vipInfo)
              }
              Vue.prototype.commonUtils.localStorageSet('token', res.data.bizData.token)
            }
            resolve(res.data)
          }).catch(ex => {
            reject(new Error('登录异常'))
          })
      })
    },
    getSysInfo ({ state, commit }) {
      return new Promise((resolve, reject) => {
        getSysInfo()
          .then(res => {
            if (res.data.rtnCode === '0') {
              commit('setHasGetInfo', true)
              Vue.prototype.commonUtils.sessionStorageSet('ALL_DIC_PARA', res.data.bizData.sysDict)
              Vue.prototype.commonUtils.sessionStorageSet('goodsclassif', res.data.bizData.goodsClassif)
              if (res.data.bizData.userInfo) {
                commit('setIsLogin', true)
                commit('setUserInfo', res.data.bizData.userInfo)
                if (res.data.bizData.companyInfo) {
                  commit('setCompanyInfo', res.data.bizData.companyInfo)
                } else {
                  commit('setCompanyInfo', null)
                }
                if (res.data.bizData.vipInfo) {
                  commit('setVipInfo', res.data.bizData.vipInfo)
                } else {
                  commit('setVipInfo', null)
                }
              } else {
                commit('setIsLogin', false)
                commit('setUserInfo', null)
              }
            }
            resolve(res.data)
          }).catch(ex => {
            reject(new Error('登录异常'))
          })
      })
    },
    // 退出登录
    handleLogOut ({ state, commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(res => {
            if (res.data.rtnCode === '0') {
              Vue.prototype.commonUtils.localStorageRemove('token')
              commit('setIsLogin', false)
              commit('hasGetInfo', false)
              commit('setCompanyInfo', null)
              commit('setVipInfo', null)
              commit('setUserInfo', null)
            }
            resolve(res.data)
          }).catch(ex => {
            reject(new Error('登出异常'))
          })
      })
    }
  }
}
