<template>
  <div style="text-align: right;margin-right: 30px;margin-bottom: 10px;">
    <span style="font-size: 14px;font-weight: 400">分享到：</span>
    <svg @click="share" class="icon"
         style="width: 20px; height: 20px;vertical-align: middle;fill: currentColor;overflow: hidden;"
         viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="761">
      <path
        d="M512 0C229.2224 0 0 229.2224 0 512s229.2224 512 512 512 512-229.2224 512-512S794.7776 0 512 0z m152.4736 616.2432l-182.8352-14.6432s160.9216-110.08 171.52-122.5216a14.63296 14.63296 0 0 0 0-21.5552L350.3616 460.8l226.3552 30.72s-173.3632 97.6384-186.88 112.64v1.8432c-0.3072 0.3072-0.6144 0.5632-0.9216 0.9216-7.168 7.68-6.7584 19.712 0.9216 26.88l274.9952-12.0832 30.0032 163.84L512 690.1248l-182.8864 95.4368 36.608-200.3968-146.3296-144.128 201.8816-29.2352L512 238.4384l89.6 174.4384 202.9568 29.6448-146.2784 142.6432 6.1952 31.0784z"
        fill="#FAB619" p-id="762"></path>
    </svg>
    <el-popover
      placement="bottom"
      width="200"
      trigger="hover">
      <qrcode :url="url" style="text-align: center;height: 180px;padding-top: 10px;"></qrcode>
      <svg  slot="reference" class="icon"
            style="margin-left: 10px;width: 20px; height: 20px;vertical-align: middle;fill: currentColor;overflow: hidden;"
            viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="731">
        <path
          d="M512 0c-282.784 0-512 229.248-512 512s229.248 512 512 512 512-229.248 512-512-229.248-512-512-512zM393.12 670.048c-39.264 0-76.672-6.816-110.88-19.04l-96.032 64.224 0-3.872 31.136-92.768c-69.984-46.016-115.392-118.592-115.392-200.512 0-139.136 130.368-251.968 291.168-251.968 146.56 0 267.456 93.792 287.808 215.744-61.184 1.76-288.192 26.048-263.232 287.104-8.128 0.576-16.288 1.088-24.576 1.088zM820.576 778.08l19.808 71.04-73.568-44.352c-28.544 10.176-59.776 15.872-92.544 15.872-135.04 0-244.512-95.168-244.512-212.608s109.472-212.608 244.512-212.608c135.04 0 244.512 95.168 244.512 212.608 0 69.664-38.688 131.264-98.176 170.048zM328.352 335.84c0 22.176-17.984 40.16-40.16 40.16s-40.16-17.984-40.16-40.16c0-22.176 17.984-40.16 40.16-40.16s40.16 17.984 40.16 40.16zM537.248 335.84c0 22.176-17.984 40.16-40.16 40.16s-40.16-17.984-40.16-40.16c0-22.176 17.984-40.16 40.16-40.16s40.16 17.984 40.16 40.16zM639.264 544.704c0 22.176-17.984 40.16-40.16 40.16s-40.16-17.984-40.16-40.16c0-22.176 17.984-40.16 40.16-40.16s40.16 17.984 40.16 40.16zM800.544 544.704c0 22.176-17.984 40.16-40.16 40.16s-40.16-17.984-40.16-40.16c0-22.176 17.984-40.16 40.16-40.16s40.16 17.984 40.16 40.16z"
          fill="#4AC94E" p-id="732"></path>
      </svg>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'index',
  data () {
    return {
      url: window.location.href
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    share () {
      let shareUrl = 'https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' +
        encodeURIComponent(this.url) +
          '&sharesource=qzone&title=' +
          this.item.title +
          '&summary=' +
          this.item.description
      window.open(shareUrl)
    }
  }
}
</script>

<style scoped>

</style>
