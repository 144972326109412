<template>
  <div v-html="html">
  </div>
</template>

<script>
export default {
  name: 'myMessage',
  data () {
    return {
      html: `<p>
      <br />
    </p>
    <p class="MsoNormal" align="center" style="text-align:center;">
      <b>大日化产业综合云服务平台会员协议</b><b></b>
    </p>
    <p class="MsoNormal">
      <b><span>甲方：</span> </b><b>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</b><b>乙方：广州日鑫互联网科技有限公司</b><b></b>
    </p>
    <p class="MsoNormal" style="margin-left:221.3500pt;text-indent:-221.3500pt;">
      <b><span>通讯地址：</span> </b><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b><b><span>通讯地址：广东省广州市天河区黄埔大道西</span>3</b><b>67</b><b><span>号中铁盛德大厦</span>B座1</b><b>502</b><b>室</b><b></b>
    </p>
    <p class="MsoNormal">
      <b><span>联系人：</span> </b><b>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</b><b>联系人：王剑锋</b><b></b>
    </p>
    <p class="MsoNormal">
      <b><span>电话：</span> </b><b>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</b><b><span>电话：</span>1</b><b>3719179825</b><b></b>
    </p>
    <p class="MsoNormal" style="text-indent:21.0000pt;">
      <span>大日化产业综合云服务平台（下称</span>“平台”），依托中国日化院构建产业的数字化服务能力，赋能行业企业提升产品品质，提升质量信任度，提供在线的检测证书溯源、在线的标准、配方、法规的文件查询及相关的业务培训。平台旨在驱动产品质量的进一步提升，通过先进的数字化技术将质量监督部门、生产企业、消费者有机联系起来，维护消费者权益。
    </p>
    <p class="MsoNormal" style="text-indent:21.0000pt;">
      甲乙双方在友好协商的情况下，签订本平台会员<span>协议</span>(下称“本协议”)，本协议阐述之条款和条件适用于甲方使用平台（访问域名为：www.darihua.com)所提供的服务内容。平台为乙方下属网站，该平台所提供的各项服务的所有权和运营权，均归属于乙方。
    </p>
    <p class="MsoNormal">
      <b>第一条</b><b>&nbsp;</b><b>会员类型及付款方式</b><b></b>
    </p>
    <p class="MsoNormal">
      1、每月<span>会员，</span>2980元/月；
    </p>
    <p class="MsoNormal">
      2、每<span>年会员，</span>19800元/年；
    </p>
    <p class="MsoNormal">
      3、每两年<span>会员，</span>29800元。
    </p>
    <p class="MsoNormal" style="text-indent:21.0000pt;">
      甲方选择的会员类型为：<u>两年会员，赠送永久会员会籍</u>。
    </p>
    <p class="MsoNormal" style="text-indent:21.0000pt;">
      乙方银行账户信息如下：
    </p>
    <p class="MsoNormal" style="text-indent:21.0000pt;">
      公司名称：广州日鑫互联网科技有限公司
    </p>
    <p class="MsoNormal" style="text-indent:21.0000pt;">
      开户银行名称：上海浦东发展银行股份有限公司广东自贸试验区南沙分行
    </p>
    <p class="MsoNormal" style="text-indent:21.0000pt;">
      银行账户：82280078801000000729
    </p>
    <p class="MsoNormal">
      <b>第二条</b><b>&nbsp;<span>会员的权力和义务</span></b><b></b>
    </p>
    <p class="MsoNormal">
      1、会员的权利：
    </p>
    <p class="MsoNormal">
      <span>（</span>1）日化行业国内外法律法规查询；
    </p>
    <p class="MsoNormal">
      <span>（</span>2）日化行业标准文本查询；
    </p>
    <p class="MsoNormal">
      <span>（</span>3）日化产品基础配方查询；
    </p>
    <p class="MsoNormal">
      <span>（</span>4）检测结果实时查看；
    </p>
    <p class="MsoNormal">
      <span>（</span>5）<span>优先检测，优先出结果，检测费用</span>9.5折；
    </p>
    <p class="MsoNormal">
      （6）检测关键节点短信提醒；
    </p>
    <p class="MsoNormal">
      <span>（</span>7）常用日化行业标准检测方法在线视频培训；
    </p>
    <p class="MsoNormal">
      <span>（</span>8）会员周期内的业务展示及推荐服务；
    </p>
    <p class="MsoNormal">
      <span>（</span>9）新原料的物化性能及应用指南资讯；
    </p>
    <p class="MsoNormal">
      <span>（</span>10）提供年度行业大数据（脱敏，两年会员享受）；
    </p>
    <p class="MsoNormal">
      <span>（</span>11）提供一次实验室间的数据比对（两年会员享受）。
    </p>
    <p class="MsoNormal">
      2、会员的义务：
    </p>
    <p class="MsoNormal">
      （1）遵守国家法律、法规以及平台已公布的相关规定，不会利用平台进行非法活动、或进行侵犯他人权利或损害他人利益的活动，并对以上行为负完全的责任；
    </p>
    <p class="MsoNormal">
      （2）保护自己的账号和交易密码，并对任何人利用该帐号所进行的活动负完全的责任；
    </p>
    <p class="MsoNormal">
      （3）主动了解平台发布的业务信息、公告和各项制度。
    </p>
    <p class="MsoNormal">
      <b>第三条</b><b>&nbsp;<span>会员</span></b><b>注意事项</b><b></b>
    </p>
    <p class="MsoNormal">
      1、甲方提交的会员及相关资料真实、准确、完整，遵守国家有关法律规定；
    </p>
    <p class="MsoNormal">
      2、甲方注册会员并付款后，会员即刻生效，会员生效后，概不退费；
    </p>
    <p class="MsoNormal">
      3、如平台终止运营，赠送的会员会籍自动取消；&nbsp;&nbsp;
    </p>
    <p class="MsoNormal">
      4<span>、根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，平台有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，平台工作人员将会提前</span>3个工作日在平台上公布修改后的协议内容，该公布行为视为平台已经通知会员变更内容，甲方需及时关注并阅读相关协议和公告。
    </p>
    <p class="MsoNormal">
      &nbsp;
    </p>
    <p class="MsoNormal">
      【以下无正文】
    </p>
    <p class="MsoNormal">
      <b><span>甲方：</span> </b><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b><b><span>乙方：广州日鑫互联网科技有限公司</span>&nbsp;</b><b>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</b>
    </p>
    <p class="MsoNormal">
      <b><b>（盖章）</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>（盖章）</b></b>
    </p>
    <p class="MsoNormal">
      <b><span>日期：</span> </b><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b><b>日期：</b><b></b>
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>`
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
