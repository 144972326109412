<template>
  <a style="cursor: pointer;"  :type="type" underline="underline" :disabled="disabled"  @click="linkTo" :target= "target"
           :icon="icon">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'index',
  props: {
    type: {
      type: String,
      required: false,
      default: ''
    },
    underline: {
      type: Boolean,
      required: false,
      default: false
    },
    notRouter: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    href: {
      type: String,
      required: false,
      default: '/'
    },
    target: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    right: {
      type: Object,
      required: false,
      default: function () {
        return null
      }
    }
  },
  methods: {
    linkTo () {
      this.doLinkTo()
    },
    doLinkTo () {
      if (this.notRouter) {
        window.open(this.href, '_self')
      } else if (this.href !== this.$route.path) {
        this.$router.push({
          path: this.href,
          replace: true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .hover-bold-link {
    text-decoration: none;
    &:hover {
      font-weight: bold !important;
    }
  }
</style>
