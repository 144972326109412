import axios from '@/api/api-request'

const projectUrl = '/login'

export const login = (data) => {
  return axios.request({
    url: projectUrl + '/login.do',
    method: 'post',
    data: data
  })
}

export const isMobileRegistered = (params) => {
  return axios.request({
    url: projectUrl + '/isMobileRegistered.do',
    method: 'post',
    params: params
  })
}
export const isAccountRegistered = (params) => {
  return axios.request({
    url: projectUrl + '/isAccountRegistered.do',
    method: 'post',
    params: params
  })
}
export const sendVerifiCode = (params) => {
  return axios.request({
    url: projectUrl + '/sendVerifiCode.do',
    method: 'post',
    params: params
  })
}

export const register = (data) => {
  return axios.request({
    url: projectUrl + '/register.do',
    method: 'post',
    data: data
  })
}

export const getSysInfo = (data) => {
  return axios.request({
    url: projectUrl + '/getSysInfo.do',
    method: 'get',
    data: data
  })
}

export const logout = () => {
  return axios.request({
    url: projectUrl + '/logout.do',
    method: 'post'
  })
}

// 找回密码发送手机验证码
export const sendFindPasswordVerifiCode = (params) => {
  return axios.request({
    url: projectUrl + '/sendFindPasswordVerifiCode.do',
    method: 'post',
    params: params
  })
}

// 登录发送手机验证码
export const sendLoginVerifiCode = (params) => {
  return axios.request({
    url: projectUrl + '/sendLoginVerifiCode.do',
    method: 'post',
    params: params
  })
}

// 找回密码
export const findPassword = (data) => {
  return axios.request({
    url: projectUrl + '/findPassword.do',
    method: 'post',
    data: data
  })
}

// getParamx
export const getParamx = () => {
  return axios.request({
    url: projectUrl + '/getParamx.do',
    method: 'get'
  })
}
