/**
 * 时间戳
 * @param {*} timestamp  时间戳
 */
const timestampToTime = (timestamp) => {
  if (timestamp == null || timestamp === '') {
    return ''
  }
  let date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '-'
  let M =
    (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  let D =
    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  let h =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  let m =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    ':'
  let s =
    date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Y + M + D + h + m + s
}

// 根据时间戳获取年月
const timestampToYearAndMonth = (timestamp) => {
  if (timestamp == null || timestamp === '') {
    return ''
  }
  let date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '-'
  let M =
    (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
  return Y + M
}

const spitConvert = (str) => {
  if (str == null || str === '') {
    return ''
  }
  return str.replace(/,/g, '、')
}

const numToPercent = (str) => {
  let realVal = ''
  if (!isNaN(str) && str !== '') {
    // 截取当前数据到小数点后三位
    let tempVal = (parseFloat(str) * 100).toFixed(1)
    realVal = tempVal + '%'
  } else {
    realVal = '--'
  }
  return realVal
}

const getStateStr = (state) => {
  if (state === '0') {
    return '正常'
  } else if (state === '1') {
    return '冻结'
  }
  return ''
}

const getOtypeStr = (otype) => {
  if (otype === '1') {
    return '集团'
  } else if (otype === '2') {
    return '下属'
  } else if (otype === '3') {
    return '公司'
  }
  return ''
}

/**
 * 将日期转为yyyy-MM-dd格式
 */
const dateToStr = (date) => {
  if (date === null || date === '') {
    return ''
  }
  var strData = new Date(date)
  var month = strData.getMonth() + 1
  var day = strData.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  return strData.getFullYear() + '-' + month + '-' + day
}
const convertMoney = (num) => {
  if (num == null || num === '') {
    return ''
  }
  num = num.toFixed(2)
  num = num.replace(/\B(?=(\d{3})+\b)/g, ',')
  return num
}

const dictConvert = (dict) => {
  if (dict == null || dict === '') {
    return ''
  }
  let dictId = dict.split('$_$')[0]
  let dictType = dict.split('$_$')[1]
  let lsDic = JSON.parse(sessionStorage.getItem('ALL_DIC_PARA'))
  let lsSel = lsDic[dictType].filter((dict) => {
    return dict.value === dictId
  })
  if (lsSel.length === 1) {
    return lsSel[0].name
  }
  return ''
}

const getSimpleText = (html) => {
  var re1 = new RegExp('<.+?>', 'g')
  var msg = html.replace(re1, '')
  return msg
}

const timeFormat = (time, format) => {
  if (time === null || time === undefined) return ''
  if (!(time instanceof Date)) time = new Date(time)
  let o = {
    'M+': time.getMonth() + 1, // 月份
    'd+': time.getDate(), // 日
    'H+': time.getHours(), // 小时
    'm+': time.getMinutes(), // 分
    's+': time.getSeconds(), // 秒
    'q+': Math.floor((time.getMonth() + 3) / 3), // 季度
    'S': time.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, (time.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return format
}

export {
  getOtypeStr, dateToStr, getStateStr, timestampToTime, spitConvert, numToPercent, timestampToYearAndMonth, dictConvert, convertMoney, getSimpleText, timeFormat
}
