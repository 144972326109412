<template>
  <span :class="getObj(values).type">{{values| formatPrice(this)}}</span>
  <!--<span v-html="nvalues"></span>-->
</template>

<script>
export default {
  name: 'index',
  props: {
    values: {
      type: Object,
      required: false,
      default: function () {
        return null
      }
    }
  },
  computed: {
    'nvalues': function () {
      if (this.values.viewType === '1') {
        return '<span class="spanClass">免费</span>'
      } else if (this.values.viewType === '2') {
        return '<span class="memberClass">会员免费</span>'
      } else if (this.values.viewType === '3') {
        if (this.values.viewPrice === null || parseFloat(this.values.viewPrice) === 0) {
          return '<span class="spanClass">免费</span>'
        }
        if (this.values.viewVipPrice === null || parseFloat(this.values.viewVipPrice) === 0) {
          return '<span class="memberClass">会员免费</span>'
        }
        return '<span class="spanClass">付费</span>'
      } else {
        return ''
      }
    }
  },
  filters: {
    formatPrice: function (val, that) {
      var msg = that.getObj(val).msg
      return msg
    }
  },
  methods: {
    getObj: function (val) {
      if (val.viewType === '1') {
        return { type: 'spanClass', msg: '免费' }
      } else if (val.viewType === '2') {
        return { type: 'memberClass', msg: '会员免费' }
      } else if (val.viewType === '3') {
        if (val.viewPrice === null || parseFloat(val.viewPrice) === 0) {
          return { type: 'spanClass', msg: '免费' }
        }
        if (val.viewVipPrice === null || parseFloat(val.viewVipPrice) === 0) {
          return { type: 'memberClass', msg: '会员免费' }
        }
        return { type: 'spanClass', msg: '付费' }
      }
      return { type: 'memberClass', msg: '会员免费' }
    }
  }
}
</script>

<style lang="scss" scoped>
  .spanClass {
    font-size: 13px;
    margin-left: 5px;
    color: #ccc;
  }
  .memberClass {
    font-size: 13px;
    margin-left: 5px;
    color: red;
  }
</style>
